<template>
    <div>
        <main class="container-fluid">
			<div class="row mx-md-4" v-if="product">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item text-secondary">
										<router-link to="/product-list?type=product&q=Semua Produk" class=" text-decoration-none">Produk</router-link>
									</li>
									<li class="breadcrumb-item text-secondary">
										<router-link :to="'/product-list?type=category&q='+ product.prod_category +'-'+ product.cat_name.toLowerCase()" class=" text-decoration-none">{{ product.cat_name | capitalize }}</router-link>
									</li>
									<li class="breadcrumb-item active" aria-current="page">{{ product.prod_name | capitalize }}</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<!-- Detil Produk -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body p-4">
							<div class="text-center my-3" v-if="isLoading || is_loadingProduct">
    						  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    						</div>
							<div class="row" v-else>
								<div class="col-md-4 px-4 pt-3" v-if="product_imageCount > 0">
									<div class="tab-content mb-3">
										<div class="tab-pane fade show active" id="satu" role="tabpanel">
											<div class="product-image border round">
												<img :src="product_imageReal(product.prod_image)" @error="imageLoadError" class="card-img-top w-100 round" :alt="product.prod_image" />
											</div>
										</div>
										<div class="tab-pane fade" id="dua" role="tabpanel">
											<div class="product-image border round">
												<img :src="product_imageItems(product.prod_image_detail[0].img_file)" @error="imageLoadError" class="card-img-top w-100 round" :alt="product.prod_image_detail[0].img_file" />
											</div>
										</div>
										<div class="tab-pane fade" id="tiga" role="tabpanel" v-if="product_imageCount > 1">
											<div class="product-image border round">
												<img :src="product_imageItems(product.prod_image_detail[1].img_file)" @error="imageLoadError" class="card-img-top w-100 round" :alt="product.prod_image_detail[1].img_file" />
											</div>
										</div>
										<!-- <div class="tab-pane fade" id="empat" role="tabpanel" v-if="product_imageCount > 2">
											<div class="product-image border round">
												<img :src="product_imageItems(product.prod_image_detail[2].img_file)" @error="imageLoadError" class="card-img-top w-100 round" :alt="product.prod_image_detail[2].img_file" />
											</div>
										</div> -->
									</div>
									<ul class="nav nav-pills mb-3 justify-content-center align-items-center" role="tablist">
										<li class="nav-item w-25 mx-1">
											<a class="nav-link active p-1 border rounded" data-toggle="pill" href="#satu">
												<img :src="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top w-100" :alt="product.prod_image" />
											</a>
										</li>
										<li class="nav-item w-25 mx-1">
											<a class="nav-link p-1 border rounded" data-toggle="pill" href="#dua" role="tab">
												<img :src="product_imageItems(product.prod_image_detail[0].img_file)" @error="imageLoadError" class="card-img-top w-100" :alt="product.prod_image_detail[0].img_file" />
											</a>
										</li>
										<li class="nav-item w-25 mx-1" v-if="product_imageCount > 1">
											<a class="nav-link p-1 border rounded" data-toggle="pill" href="#tiga" role="tab">
												<img :src="product_imageItems(product.prod_image_detail[1].img_file)" @error="imageLoadError" class="card-img-top w-100" :alt="product.prod_image_detail[1].img_file" />
											</a>
										</li>
										<!-- <li class="nav-item w-25" v-if="product_imageCount > 2">
											<a class="nav-link p-1" data-toggle="pill" href="empat" role="tab">
												<img :src="product_imageItems(product.prod_image_detail[1].img_file)" @error="imageLoadError" class="card-img-top w-100" :alt="product.prod_image_detail[1].img_file" />
											</a>
										</li> -->
									</ul>
								</div>
								<div class="col-md-4 px-4 pt-3" v-else>
									<div class="product-image border round">
										<img :src="product_imageReal(product.prod_image)" @error="imageLoadError" class="card-img-top w-100" :alt="product.prod_image" />
									</div>
								</div>
								<div class="col-md-8 px-3 py-2">
									<h3 class="card-text mb-1">{{ product.prod_name | capitalize }}</h3>
									<p class="card-text text-secondary">{{ product.prod_summary }}</p>
									<ul class="list-group list-group-horizontal-md border-bottom mb-4">
										<li class="list-group-item border-0">
											<div class="d-inline-flex">
												<span class="text-secondary pr-2" v-if="product.prod_rating">({{ product.prod_rating }})</span>
												<div class="d-inline-flex justify-content-center align-items-center" v-for="index in 5" :key="index">
													<i class="fa fa-star fa-lg text-warning" v-if="index <= product.prod_rating"></i>
													<div class="fa-stack fa-lg" style="height: 16px; line-height: 16px; width: 24px;" v-else-if="index - 0.5 <= product.prod_rating">
														<i class="fa-stack-1x fa fa-star text-secondary"></i>						
														<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
													</div>
													<i class="fa fa-star fa-lg text-secondary" v-else></i>
												</div>
												<span class="text-secondary pl-2" v-if="product.count_reviewer">({{ product.count_reviewer }} Ulasan)</span>
											</div>
										</li>
										<li class="list-group-item border-left border-right border-top-0 border-bottom-0 rounded-0">
											<button class="btn p-0 product-favorit" @click="add_favorite(product.prod_id)">
												<i class="fa fa-heart fa-lg"></i>
												<span class="pl-2">Favoritkan</span>
											</button>
										</li>
										<li class="list-group-item border-0">
											<button class="btn p-0 product-bandingkan" @click="add_compare(product)">
												<i class="fa fa-sliders-h fa-lg"></i>
												<span class="pl-2">Bandingkan</span>
											</button>
										</li>
									</ul>
									<table class="table table-borderless w-100 mb-4">
										<tr class="border-bottom">
											<td class="w-25"><span class="card-text text-secondary">Harga</span></td>
											<td class="w-75">
												<!-- <div class="d-md-inline-flex" v-if="product.prod_discount && product.prod_discount != 0">
													<h4 class="text-success mb-0 pr-2"><strong>{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</strong></h4>
													<p class="mb-0 d-flex align-items-center justify-content-start">
														<small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small> 
														<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency('IDR') }}</strike>
													</p>
												</div>
												<h4 class="text-success mb-0" v-else><strong>{{ product.prod_price | formatCurrency('IDR') }}</strong></h4> -->
												<h4 class="text-success mb-0"><strong>-</strong></h4>

											</td>
										</tr>
										<tr class="border-bottom">
											<td class="w-25"><span class="card-text text-secondary">Jumlah</span></td>
											<td class="w-75">
												<div class="jumlah">
													<!-- <button class="minus btn btn-success" @click="minusCount()" v-if="jumlah > 1">-</button>
													<button class="minus btn btn-success disabled" v-else>-</button>
													<input id="jumlah-pesan" class="jumlah-produk" style="width: 80px;" type="number" :value="jumlah" @keyup="fixedCount($event)" required v-if="product.prod_stock> 0" />
													<input class="jumlah-produk" style="width: 80px;" type="text" placeholder="0" disabled v-else />
													<button class="plus btn btn-success" @click="plusCount()" v-if="jumlah < product.prod_stock">+</button>
													<button class="plus btn btn-success disabled" v-else>+</button> -->
													
													<button class="minus btn btn-success" @click="minusCount()" v-if="jumlah > 1">-</button>
													<button class="minus btn btn-success disabled" v-else>-</button>
													<input id="jumlah-pesan" class="jumlah-produk" style="width: 110px;" type="number" :value="jumlah" @keyup="fixedCount($event)" required />
													<button class="plus btn btn-success" @click="plusCount()">+</button>
												</div>
											</td>
										</tr>
										<tr class="border-bottom">
											<td class="w-25"><span class="card-text text-secondary">Stok</span></td>
											<td class="w-75" v-if="product.prod_stock > 0">{{ product.prod_stock }} Buah &nbsp;<button class="btn btn-success btn-sm disabled">Tersedia &nbsp;<i class="fa fa-check-circle"></i></button></td>
											<td class="w-75" v-else><button class="btn btn-danger btn-sm disabled">Produk Kosong!&nbsp;<i class="fa fa-times-circle"></i></button></td>
										</tr>
										<tr class="border-bottom text-center" v-if="product.umkm">
											<td colspan="2"><span class="card-text text-secondary"><i class="fa fa-store"></i> Produk dari UMKM {{ product.umkm | capitalize }}</span></td>
										</tr>
									</table>
									<div class="w-100 rounded-lg text-center">
										<div class="btn-group w-50" v-if="product.prod_stock">
											<!-- <button class="btn btn-info w-50" @click="plusKeranjang()"><h6 class="my-2">Keranjang &nbsp;<i class="fa fa-cart-plus"></i></h6></button> -->
											<a href="https://wa.me/06281310005877" target="_blank" class="btn btn-success w-50"><h6 class="my-2">Hubungi Kami &nbsp;<i class="fa fa-phone"></i></h6></a>
										</div>
									</div>
									<div class="row" v-if="product.prod_bidding == 1">
										<div class="col-md-12 col-lg-10 offset-lg-1 px-3">
											<div v-if="product.prod_stock">
												<div class="col-md-8 offset-md-2 text-left border border-warning rounded shadow px-4 py-3 mt-4" style="height: fit-content;">
													<h6>Nego Harga</h6>
													<form @submit.prevent="nego_hargaProduk">
														<div class="form align-items-center">
															<div class="input-group">
																<div class="input-group-prepend">
																	<div class="input-group-text bg-light">
																		<h6 class="mb-0">Rp</h6>
																	</div>	
																</div>
																<input type="text" v-model.trim="harga_nego" class="form-control" placeholder="Masukan harga dari Anda di sini!" v-formatIDR required />
																<div class="input-group-append">
																	<button class="btn btn-outline-warning" type="submit" v-if="isLoggedIn">Nego!</button>
																	<router-link to="/login" class="btn btn-outline-warning" type="submit" v-else>Nego!</router-link >
																</div>
															</div>
														</div>
													</form>
													<p class="mb-0 mt-1 text-muted">
														Untuk nego harga, masukkan harga yang anda inginkan kemudian tunggu persetujuan dari admin.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<hr class="my-4" />
								</div>
								<div class="col-md-12">
									<ul class="nav nav-pills mb-2 border rounded-lg shadow" id="pills-tab" role="tablist">
										<li class="nav-item w-50 text-center">
											<a class="nav-link rounded-lg active" data-toggle="tab" href="#deskripsi-produk-tab" role="tab">
												<h6 class="my-1"><i class="fa fa-edit"></i>&nbsp; Deskripsi</h6>
											</a>
										</li>
										<li class="nav-item w-50 text-center">
											<a class="nav-link rounded-lg" data-toggle="tab" href="#ulasan-produk-tab" role="tab">
												<h6 class="my-1"><i class="fa fa-user-edit"></i>&nbsp; Ulasan</h6>
											</a>
										</li>
									</ul>
									<div class="tab-content border rounded-lg shadow px-4 py-3">
										<div class="tab-pane fade show active" id="deskripsi-produk-tab" role="tabpanel">
											<p class="text-secondary">Deskripsi Produk:</p>
											<div class="max-content-user customScroolBar" style="max-height: 100vh">
												<p class="text-secondary" v-html="product.prod_desc"></p>
											</div>
										</div>
										<div class="tab-pane fade" id="ulasan-produk-tab" role="tabpanel">
											<p class="text-secondary">Ulasan Produk:</p>
											<div class="row px-4" v-if="total_reviews > 0">
												<div class="col-md-6 px-2" v-for="(review, index) in reviews" :key="index">
													<div class="card shadow mb-3">
														<div class="row px-5 d-flex align-items-center my-3">
															<div class="col-md-3 pr-0 text-center">
																<img v-lazy="profileImage(review.member_profile)" @error="imageLoadError"  class="border rounded-circle w-100" :alt="review.member_profile" />
															</div>
															<div class="col-md-9">
																<h6 class="text-secondary mb-0 pr-3">{{ review.member_name }}</h6>
																<p class="text-secondary mb-0 pr-3">{{ review.review_date | momentFormatID }}</p>
																<div class="text-left"><hr class="w-100 my-2" /></div>
																<div class="d-inline-flex" v-for="index in 5" :key="index">
																	<div v-if="review.review_star >= index">
																		<i class="fa fa-star text-warning fa-md"></i>
																	</div>
																	<div v-else>
																		<i class="fa fa-star text-secondary fa-md"></i>
																	</div>
																</div>
																<p class="text-dark">{{ review.review_content }}</p>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-12 text-center mt-1">
													<hr />
													<b-pagination v-model="current_page" :per-page="per_page" @change="get_reviewProduct(product.prod_id)" :total-rows="total_reviews" first-number last-number align="center" size="md"></b-pagination>
												</div>
											</div>
											<div class="text-center" v-else>
												<h5 class="text-info mb-0">Oops!</h5>
												<p class="text-muted mb-3">Belum ada ulasan yang tersedia</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<div v-else>
				 <main class="container-fluid">
    		        <div class="text-center py-5">
						<h3 class="text-info mb-1">Oops!</h3>
						<h5 class="text-info">Produk tidak ditemukan</h5>
						<a href="#" @click="$router.go(-1)" class="text-secondary text-decoration-none">Kembali</a>
					</div>
				</main>
			</div>
			
			
			<!-- Produk Terkait / RECOMENDED PRODUCTS -->
            <div v-if="recomendedProducts.length">
				<div class="row mx-md-4">
					<!-- Produk Terkait -->
					<div class="col-md-12 mb-4">
						<div class="card shadow border-0 round">
							<div class="card-body p-4">
								<h5 class="mb-0 mt-2 pl-3"><i class="far fa-check-square fa-lg"></i> &nbsp;Produk Lainnya</h5>
								<div class="carousel-wrap w-100">
									<!-- <div class="text-center my-3" v-if="isLoading || isLoadingRecomended">
										<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div> -->
									<div class="owl-carousel owl-product-12 py-3 px-2">
										<div class="item card product" v-for="product in recomendedProducts" :key="product.prod_id">
											<router-link :to="'/product-detail/' + product.prod_id + '-' + product.prod_name.toLowerCase()" class="text-decoration-none">
											<div class="d-flex align-items-center" style="height: 188px; overflow: hidden;">
												<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
											</div>
											<div class="card-body py-2 text-center">
												<div class="d-inline-flex justify-content-center align-items-center" v-for="index2 in 5" :key="index2">
												<i class="fa fa-star fa-sm text-warning" v-if="index2 <= product.prod_rating"></i>
												<div class="fa-stack fa-sm" style="height: 16px; line-height: 22px; width: 15px;" v-else-if="index2 - 0.5 <= product.prod_rating">
													<i class="fa-stack-1x fa fa-star text-secondary"></i>
													<i class="fa-stack-1x fa fa-star-half text-warning"></i>
												</div>
												<i class="fa fa-star fa-sm text-secondary" v-else></i>
												</div>
			
												<p class="card-text text-dark mb-0 d-flex justify-content-center" style="height:50px; overflow: hidden;">{{ product.prod_name | str_limit(25) | capitalize }}</p>
												<p class="text-muted mb-0 text-truncate">{{ product.prod_summary }}</p>
												<!-- <div class="" v-if="product.prod_discount">
												<p class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency("IDR") }}</p>
												<label class="d-flex justify-content-center align-items-center">
													<small class="bg-danger text-white round px-2">{{ product.prod_discount }}%</small>
													<strike class="text-muted pl-1">{{ product.prod_price | formatCurrency("IDR") }}</strike>
												</label>
												</div>
												<p class="text-success my-3" v-else>{{ product.prod_price | formatCurrency("IDR") }}</p> -->
												<p class="text-success my-3">-</p>
											</div>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import Vue from "vue";

export default {
  	data () {
 		return {
			jumlah: 1,
			product: [],
			stok: 0,
			product_imageCount: 0,
			is_loadingProduct: false,
			isRefresh: false,
			reviews: [],
			current_page: 1,
			per_page: 0,
			total_reviews: 0,
			harga_nego: 0,
			recomendedProducts: [],
			isLoadingRecomended: false,
 		}
	},
    computed: {
      	isLoggedIn : function(){
      	  	return this.$store.getters.isLoggedIn
      	},
		isLoading() {
			return this.$store.getters.isLoading
		},
		// product_catName() {
		// 	try {
		// 		return this.product.cat_name.toLowerCase()
		// 	}
		// 	catch(e) {
		// 		return null
		// 	}
		// },
	},
	methods: {
		minusCount() {
			if(this.jumlah > 1) {
				this.jumlah--
			}
			else {
				$('#jumlah-pesan').val(this.jumlah)
			}
		},
		plusCount() {
			if(this.jumlah < this.stok) {
				this.jumlah++
			}
		},
		fixedCount($event) {
			if(!isNaN($event.target.value)) {
				if($event.target.value <= this.stok) {
					if($event.target.value > 0) {
						this.jumlah = $event.target.value
					}
					else {
						$('#jumlah-pesan').val(this.jumlah)
					}
				}
				else {
					this.jumlah = this.stok
					$('#jumlah-pesan').val(this.stok)
				}
			}
		},
		plusKeranjang() {

			if(this.isLoggedIn) {
				let formData = new FormData();
				formData.append('prod_id', this.product.prod_id);
				formData.append('prod_qty', this.jumlah);

				window.axios.post('/cart', formData, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
                        }
                    }
                )
 				.then((response) => {
					window.NavbarDirtyAccess.get_productKeranjang()
					this.$router.push('/member/shopping-cart')
 				})
 				.catch((error) => {
 					console.log(error)
				})
			}
			else {
				this.$session.set('product_keranjang', [{'prod_id': this.product.prod_id, 'prod_qty': this.jumlah}])
				this.$router.push('/login')
			}
		},
		get_detail_product(id) {
			this.is_loadingProduct = true
			window.axios.get('/catalogue/detail/'+ id)
 			.then((response) => {
				this.jumlah = 1
				this.product = response.data.data
				// this.stok = this.product.prod_stock
				this.stok = 99999999
				this.product_imageCount = this.product.prod_image_detail.length
				$('.product-image').trigger('zoom.destroy')
				this.is_loadingProduct = false
				
				this.recomendedProducts = []
				this.isLoadingRecomended = true
				this.getRecomendedProducts(response.data.data)
 			})
 			.catch((error) => {
 				console.log(error)
				this.$router.go(-1)
				this.$swal({
					title: 'Oops', 
					html: '<p>Produk tidak ditemukan.</p>',
					icon: 'error',
					timer: 3000,
					showConfirmButton: false,
				});
			})
		},
		getRecomendedProducts(data) {
			window.axios
				.get("/catalogue/detail/more-product?prod_id="+data.prod_id+"&brand_id="+data.prod_brand+"&category_id="+data.prod_category+"&limit=10")
				.then((response) => {
					this.recomendedProducts = response.data.data;
	
					Vue.nextTick(function() {
						$(".owl-product-12").owlCarousel({
							loop: true,
							margin: 10,
							nav: false,
							autoplay: false,
							autoplayHoverPause: true,
							responsive: {
							0: {
								items: 2,
							},
							600: {
								items: 4,
							},
							1000: {
								items: 6,
							},
							},
						});
					});
					this.isLoadingRecomended = false
				})
				.catch((error) => {
					console.log(error);
				});
		},
		nego_hargaProduk() {
			if(this.harga_nego != '') {
				let formData = new FormData();
				formData.append('prod_id', this.product.prod_id);
				formData.append('nego_price', this.harga_nego.replace(/\D/g,''));
				
				window.axios.post('/nego', formData, {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
						}
					}
				)
				.then((response) => {
					this.$router.push('/member/bargaining')
				})
				.catch((error) => {
					this.$router.push('/member/bargaining')
					this.$swal({
						title: 'Oops', 
						html: '<p>Terjadi Kesalahan</p>',
						icon: 'error',
						timer: 3000,
						showConfirmButton: false,
					});
				})
			}
		},
		add_favorite(id) {
			if(this.isLoggedIn) {
				let formData = new FormData();
				formData.append('prod_id', id);

				window.axios.post('/wishlist', formData, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
                        }
                    }
                )
 				.then((response) => {
					window.NavbarDirtyAccess.get_productFavorite()
 				})
 				.catch((error) => {
					if(!error.response.data.success) {
						this.$swal({
							title: 'Oops!', 
							html: '<p>Produk sudah pernah ditambahkan ke dalam Wishlist sebelumnya.</p>',
							icon: 'error',
							timer: 7000,
							showConfirmButton: false,
						});
					}
					this.$router.push('/member/favorite')
				})
				.finally(() => {
					this.$router.push('/member/favorite')
				})
			}
			else {
				this.$router.push('/login')
			}
		},
		add_compare(product) {
			
			let id_productSaved = ''
			let flag = 1
			let product_toSave = JSON.stringify(product)
			
			if(!localStorage.getItem('prod_compare_0')) {
				
				// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
				if(localStorage.getItem('prod_compare_1')) {
					id_productSaved = JSON.parse(localStorage.getItem('prod_compare_1')).prod_id
					
					if(id_productSaved == product.prod_id) {
						flag = 0
					}
				}
				if(localStorage.getItem('prod_compare_2')) {
					id_productSaved = JSON.parse(localStorage.getItem('prod_compare_2')).prod_id
					
					if(id_productSaved == product.prod_id) {
						flag = 0
					}
				}
				
				if(flag == 1) {
					localStorage.setItem('prod_compare_0', product_toSave)
				}
			}
			else {
				if(!localStorage.getItem('prod_compare_1')) {
					// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
					if(localStorage.getItem('prod_compare_0')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_0')).prod_id
						
						if(id_productSaved == product.prod_id) {
							flag = 0
						}
					}
					if(localStorage.getItem('prod_compare_2')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_2')).prod_id
						
						if(id_productSaved == product.prod_id) {
							flag = 0
						}
					}
					
					if(flag == 1) {
						localStorage.setItem('prod_compare_1', product_toSave)
					}
				}
				else {
					// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
					if(localStorage.getItem('prod_compare_0')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_0')).prod_id
						
						if(id_productSaved == product.prod_id) {
							flag = 0
						}
					}
					if(localStorage.getItem('prod_compare_1')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_1')).prod_id
						
						if(id_productSaved == product.prod_id) {
							flag = 0
						}
					}
					
					if(flag == 1) {
						localStorage.setItem('prod_compare_2', product_toSave)
					}
				}
			}
			
			if(flag == 0) {
				this.$swal({
					title: 'Oops!', 
					html: '<p>Produk sudah pernah ditambahkan sebelumnya.</p>',
					icon: 'error',
					timer: 7000,
					showConfirmButton: false,
				});
			}
			
			this.$router.push('/product/compare')
		},
		get_reviewProduct(id, current_page) {
			this.isRefresh = true
			window.axios.get('/catalogue/detail/'+ id +'/review?page='+ current_page, {
       		        headers: {
						"token": this.$store.getters.token_member
       		        }
				}
			)
 			.then((response) => {
				this.reviews = response.data.data.data
				this.per_page = response.data.data.per_page
				this.total_reviews = response.data.data.total
				this.current_page = current_page

				let position = $(document).scrollTop()
				$('html, body').stop().animate({
					scrollTop: 83
				}, 1000, 'easeInOutExpo')
				this.isRefresh = false
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
	},
	updated() {
		$('.product-image').zoom()
		
		$('.tabs-content').find('li').hide()
		var activeIndex = 0
      	var $contentlis = $('.tabs-content li')
    	var $tabslis = $('.tabs li')
		$contentlis.eq(activeIndex).show()

  		$('.tabs').on('click', 'li', function (e) {
    		var $current = $(e.currentTarget)
        	var index = $current.index()
    		$tabslis.removeClass('active-tab')
    		$current.addClass('active-tab')
    		$contentlis.hide().eq(index).show()
		})
	},
    created () {
		this.$session.start()
		$(document).scrollTop(0)
		this.get_detail_product(this.$route.params.id)
		this.get_reviewProduct(this.$route.params.id)

	},
	watch: {
    	'$route.params.id': function (id) {
			$('html, body').stop().animate({
				scrollTop: 0
			}, 1000, 'easeInOutExpo')
      		this.get_detail_product(id)
			this.get_reviewProduct(id)
		}
  	},
}
</script>